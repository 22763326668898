$COL1: #1b1f22; //
$COLFLAT1: #1b1f22; //
$COL2: #034da2; //
$COLFLAT2: #034da2; //
$COL3: #ff822e; //
$COLFLAT3: #ff822e; //
$COL4: #1b1f22; //
$COLFLAT4: #1b1f22; //
$COL5: #ffffff; //
$COLFLAT5: #ffffff; //
$COL6: #292c30; //
$COLFLAT6: #292c30; //
$COL7: #ffffff; //
$COLFLAT7: #ffffff; //
$COL8: #ffffff; //
$COLFLAT8: #ffffff; //
$COL9: #ffffff; //
$COLFLAT9: #ffffff; //
$COL10: #ffffff; //
$COLFLAT10: #ffffff; //
$COL11: #1b1f22; //
$COLFLAT11: #1b1f22; //
$COL12: #ffffff; //
$COLFLAT12: #ffffff; //
$COL13: #1b1f22; //
$COLFLAT13: #1b1f22; //
$COL14: #ffffff; //
$COLFLAT14: #ffffff; //
$COL15: #1b1f22; //
$COLFLAT15: #1b1f22; //
$COL16: #ffffff; //
$COLFLAT16: #ffffff; //
$COL17: #ffffff; //
$COLFLAT17: #ffffff; //
$COL18: #ffffff; //
$COLFLAT18: #ffffff; //
$COL19: #fefefe; //
$COLFLAT19: #fefefe; //
$COL20: #ffffff; //white
$COLFLAT20: #ffffff; //white
$COL21: #2a2d31; //Menu Background
$COLFLAT21: #2a2d31; //Menu Background
$COL22: #a68153; //Gold
$COLFLAT22: #a68153; //Gold
$COL23: #313539; //Footer Background
$COLFLAT23: #313539; //Footer Background
$COL24: #ffffff; //
$COLFLAT24: #ffffff; //
$COL25: #034da2; //
$COLFLAT25: #034da2; //
$COL26: #e0e0e0; //Light Grey
$COLFLAT26: #e0e0e0; //Light Grey
$COL27: #0562cc; //light blue
$COLFLAT27: #0562cc; //light blue
$COL28: #f2f2f2; //Light Grey
$COLFLAT28: #f2f2f2; //Light Grey
$COL29: rgba(27,31,34,0.812); //Transparent submenu
$COLFLAT29: #2e3134; //Transparent submenu
$COL30: rgba(255,255,255,0.2); //white 20%
$COLFLAT30: #999999; //white 20%
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
