body { background-color: $COL1; }
body { color: $COL7; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL12;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h2 {
color: $COL13;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 40px;

}
}
h3 {
color: $COL14;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 22px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL15;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL16;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL17;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL11;
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 20px;

}
body {
font-family: 'Open Sans';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 16px;

@media #{$large-up} {
font-size: 18px;

}
}
p { padding-bottom: 1em; }
a {color: $COL8}
a:hover {color: $COL25}
/* Primary:3 */
.MES3 {
background-color: $COL2;
color: $COL9;
&:hover { color: $COL5;}
 }
/* Primary:4 */
.MES4 {
background-color: $COL2;
color: $COL9;
 }
.MES4 {
background-color: $COL2;
color: $COL9;
h1.MEC4, h2.MEC4, h3.MEC4, h4.MEC4, h5.MEC4, h6.MEC4 { color: $COL9;
 }
h1.MEC4 { font-size: 26.4px; }
h1.MEC4 { @media #{$medium-up} { font-size: 35px; }; }
h2.MEC4 { font-size: 26.4px; }
h2.MEC4 { @media #{$medium-up} { font-size: 35px; }; }
h3.MEC4 { font-size: 26.4px; }
h3.MEC4 { @media #{$medium-up} { font-size: 30px; }; }
h4.MEC4 { font-size: 21.6px; }
h4.MEC4 { @media #{$medium-up} { font-size: 25px; }; }
h5.MEC4 { font-size: 20.4px; }
h5.MEC4 { @media #{$medium-up} { font-size: 20px; }; }
h6.MEC4 { font-size: 19.2px; }
h6.MEC4 { @media #{$medium-up} { font-size: 18px; }; }
 }
cite.MEC4{
color: $COL9;
}
/* Secondary:5 */
.MES5 {
background-color: $COL3;
color: $COL10;
 }
/* Secondary:6 */
.MES6 {
background-color: $COL3;
color: $COL10;
 }
.MES6 {
background-color: $COL3;
color: $COL10;
h1.MEC6, h2.MEC6, h3.MEC6, h4.MEC6, h5.MEC6, h6.MEC6 { color: $COL10;
 }
 }
cite.MEC6{
color: $COL10;
}
/* Dark:7 */
.MES7 {
background-color: $COL4;
color: $COL18;
 }
/* Dark:8 */
.MES8 {
background-color: $COL4;
color: $COL5;
 }
.MES8 {
background-color: $COL4;
color: $COL5;
h1.MEC8, h2.MEC8, h3.MEC8, h4.MEC8, h5.MEC8, h6.MEC8 { color: $COL5;
 }
 }
a.MEC8 { color: $COL5;
&:hover { color: $COL27; }
 }
cite.MEC8{
color: $COL5;
}
/* Light:9 */
.MES9 {
background-color: $COL5;
 }
/* Light:10 */
.MES10 {
background-color: $COL5;
 }
.MES10 {
background-color: $COL5;
 }
/* Shade 1:11 */
.MES11 {
background-color: $COL6;
color: $COL24;
 }
/* Shade 1:12 */
.MES12 {
background-color: $COL6;
color: $COL24;
 }
.MES12 {
background-color: $COL6;
color: $COL24;
h1.MEC12, h2.MEC12, h3.MEC12, h4.MEC12, h5.MEC12, h6.MEC12 { color: $COL24;
 }
 }
cite.MEC12{
color: $COL24;
}
/* Mobile Header:13 */
.MES13 {
background-color: $COL21;
color: $COL20;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent $COL1 transparent;
 }
.MES13 {
background-color: $COL21;
color: $COL20;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
border-width: 0 0 3px 0;
border-style: solid;
border-color: transparent transparent $COL1 transparent;
 }
a.MEC13 { color: $COL20;
&:hover { color: $COL22; }
 }
cite.MEC13{
color: $COL20;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
}
/* home block blue:14 */
.MES14 {
background-color: $COL20;
color: $COL4;
border-width: 5px 1px 1px 1px;
border-style: solid;
border-color: $COL2 $COL5 $COL5 $COL5;
 }
.MES14 {
background-color: $COL20;
color: $COL4;
border-width: 5px 1px 1px 1px;
border-style: solid;
border-color: $COL2 $COL5 $COL5 $COL5;
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: $COL4;
 }
 }
.MEC14 li {color: $COL4;}
cite.MEC14{
color: $COL4;
}
/* :15 */
.MES15 {
background-color: $COL20;
color: $COL4;
border-width: 5px 0 0 0;
border-style: solid;
border-color: $COL3 transparent transparent transparent;
 }
.MES15 {
background-color: $COL20;
color: $COL4;
border-width: 5px 0 0 0;
border-style: solid;
border-color: $COL3 transparent transparent transparent;
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL4;
 }
 }
.MEC15 li {color: $COL4;}
cite.MEC15{
color: $COL4;
}
/* Hollaw:16 */
.MES16 {
color: $COL20;
&:hover { color: $COL20;}
border-width: 1px;
border-style: solid;
border-color: $COL20;
&:hover { border-color: $COL20; }
 }
/* :17 */
.MES17 {
 }
/* Contents:18 */
.MES18 {
background-color: $COL20;
&:hover, &.hover { background-color: $COL20;}
color: $COL4;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
 }
.MES18 {
background-color: $COL20;
&:hover, &.hover { background-color: $COL20;}
color: $COL4;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: $COL1;
 }
 }
a.MEC18 { color: $COL4;
&:hover { color: $COL4; }
 }
cite.MEC18{
color: $COL4;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
}
/* Slider text 2:19 */
.MES19 {
 }
.MES19 {
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL20;
 }
 }
/* Footer Dark:20 */
.MES20 {
background-color: $COL23;
color: $COL26;
font-size: 12.8px;
@media #{$large-up} {
font-size: 14.4px;
};
 }
.MES20 {
background-color: $COL23;
color: $COL26;
font-size: 12.8px;
@media #{$large-up} {
font-size: 14.4px;
};
h1.MEC20, h2.MEC20, h3.MEC20, h4.MEC20, h5.MEC20, h6.MEC20 { color: $COL26;
 }
 }
a.MEC20 { color: $COL20;
&:hover { color: $COL22; }
 }
cite.MEC20{
color: $COL26;
font-size: 12.8px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* News grid:21 */
.MES21 {
background-color: $COL26;
color: $COL4;
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
border-width: 0 1px 1px 1px;
border-style: solid;
border-color: transparent $COL26;
 }
.MES21 {
background-color: $COL26;
color: $COL4;
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
border-width: 0 1px 1px 1px;
border-style: solid;
border-color: transparent $COL26;
h1.MEC21, h2.MEC21, h3.MEC21, h4.MEC21, h5.MEC21, h6.MEC21 { color: $COL1;
 }
 }
cite.MEC21{
color: $COL4;
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* About FAQ:22 */
details.MES22 {
& > summary{background-color: $COL5;
}
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* Home FAQ:23 */
details.MES23 {
& > summary{background-color: $COL2;
}
color: $COL20;
& > article { color: $COL20;
 }
& > summary{padding: 10px 0;}

& > article {border-width: 0 0 1px 0;
border-style: solid;
border-color: transparent transparent $COL20 transparent;
} }
/* 60px space:24 */
.MES24 {
padding: 15px 0;

 }
/* Side Menu:25 */
.MES25 {
background-color: $COL21;
color: $COL20;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
 }
.MES25 {
background-color: $COL21;
color: $COL20;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
 }
a.MEC25 { color: $COL20;
&:hover { color: $COL22; }
 }
cite.MEC25{
color: $COL20;
font-size: 16px;
@media #{$large-up} {
font-size: 18px;
};
}
/* Home Image Panel:26 */
.MES26 {
background-color: $COL1;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://surfsideservices.com.au/img/374/63');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL24;
min-height:300px;
@media #{$medium-up} {
min-height: 350px;};
@media #{$large-up} {
min-height: 500px;};
 }
.MES26 {
background-color: $COL1;
& > .underlay, & > .inner-overlay { opacity: 0.6 }
& { position: relative;}
& > .me-iwrap { position: relative; z-index: 1; }
& > .underlay { background-image: url('https://surfsideservices.com.au/img/374/63');
background-position: center center;
background-repeat: no-repeat;
top: 0;
background-size: cover;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 0;
 }
& > * {position: relative;}color: $COL24;
min-height:300px;
@media #{$medium-up} {
min-height: 350px;};
@media #{$large-up} {
min-height: 500px;};
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: $COL24;
 }
 }
cite.MEC26{
color: $COL24;
}
/* Home Slider Dark Text:27 */
.MES27 {
 }
.MES27 {
h1.MEC27, h2.MEC27, h3.MEC27, h4.MEC27, h5.MEC27, h6.MEC27 { color: $COL23;
 }
 }
/* Primary:28 */
.MES28 {
background-color: $COL3;
&:hover { background-color: $COL27;}
color: $COL20;
&:hover { color: $COL5;}
font-size: 15.4px;
@media #{$medium-up} {
font-size: 19.2px;
};
@media #{$large-up} {
font-size: 20.8px;
};
padding: 15px 30px;

 }
/* Mobile Header Panel:29 */
.MES29 {
background-color: $COL2;
color: $COL9;
font-size: 24px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 18px;
};
 }
.MES29 {
background-color: $COL2;
color: $COL9;
font-size: 24px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 18px;
};
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: $COL9;
 }
h1.MEC29 { font-size: 26.4px; }
h1.MEC29 { @media #{$medium-up} { font-size: 35px; }; }
h2.MEC29 { font-size: 26.4px; }
h2.MEC29 { @media #{$medium-up} { font-size: 35px; }; }
h3.MEC29 { font-size: 26.4px; }
h3.MEC29 { @media #{$medium-up} { font-size: 30px; }; }
h4.MEC29 { font-size: 21.6px; }
h4.MEC29 { @media #{$medium-up} { font-size: 25px; }; }
h5.MEC29 { font-size: 20.4px; }
h5.MEC29 { @media #{$medium-up} { font-size: 20px; }; }
h6.MEC29 { font-size: 19.2px; }
h6.MEC29 { @media #{$medium-up} { font-size: 18px; }; }
 }
cite.MEC29{
color: $COL9;
font-size: 24px;
@media #{$medium-up} {
font-size: 16px;
};
@media #{$large-up} {
font-size: 18px;
};
}
/* Light Grey:30 */
.MES30 {
background-color: $COL28;
color: $COL4;
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
border-width: 0 1px 1px 1px;
border-style: solid;
border-color: transparent $COL26;
 }
.MES30 {
background-color: $COL28;
color: $COL4;
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
border-width: 0 1px 1px 1px;
border-style: solid;
border-color: transparent $COL26;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: $COL1;
 }
 }
cite.MEC30{
color: $COL4;
font-size: 16px;
@media #{$large-up} {
font-size: 14.4px;
};
}
/* Blue header block:31 */
.MES31 {
background-color: $COL2;
color: $COL5;
 }
.MES31 {
background-color: $COL2;
color: $COL5;
h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: $COL5;
 }
 }
a.MEC31 { color: $COL5;
&:hover { color: $COL27; }
 }
cite.MEC31{
color: $COL5;
}
/* slide wrap:32 */
.MES32 {
color: $COL1;
padding: 25px;

@media #{$medium-up} {
padding: 40px 15px;

}
@media #{$large-up} {
padding: 50px 15px;

}
 }
.MES32 {
color: $COL1;
padding: 25px;

@media #{$medium-up} {
padding: 40px 15px;

}
@media #{$large-up} {
padding: 50px 15px;

}
 }
cite.MEC32{
color: $COL1;
}
/* Main Menu:33 */
nav.me-Menu.MES33 {
.menu-item.MEC33{background-color: $COL1;
 &:hover {background-color: $COL21}
}
& .menu-item.MEC33, & .menu-item.MEC33 > div.MEC33{ & > a.MEC33{color: $COL20;
font-size: 16px;
text-transform: uppercase;
}
 &:hover > a.MEC33{color: $COL5;
}
 }
.menu-item.MEC33 { border-width: 0 0 1px 0;
border-style: solid;
border-color: $COL23;
 }
&.horizontal > .menu-item.MEC33 { border:0;
border-color: $COL23;
border-style: solid;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC33 { border:0;
border-color: $COL23;
border-style: solid;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC33 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC33 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Blue button:34 */
.MES34 {
background-color: $COL2;
&:hover { background-color: $COL27;}
color: $COL20;
&:hover { color: $COL5;}
font-size: 15.4px;
@media #{$medium-up} {
font-size: 19.2px;
};
@media #{$large-up} {
font-size: 20.8px;
};
padding: 15px 30px;

 }
